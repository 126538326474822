/**
 * @module lineChart
 * @description Draws line charts
 */
import {createChart} from '../../admin/modules/chartHelper.js';
import {getDefaultLineConfig} from './defaultChartConfigs.js';

class LineChart extends HTMLElement {
    /** Called each time the element is added to the document. */
    connectedCallback() {
        const canvas = this.querySelector('canvas');
        const exportButton = this.querySelector('[data-export]');
        const data = this.#getChartData();
        const configuration = {
            ...getDefaultLineConfig(),
            data,
        };

        const pluginOptions = JSON.parse(this.getAttribute('plugin-options'));
        pluginOptions.tooltipConfigName = this.getAttribute('tooltips');

        createChart(canvas, configuration, pluginOptions);

        if (exportButton) {
            exportButton.classList.remove('hidden');
            exportButton.addEventListener('click', this.#exportChartData.bind(this, exportButton.dataset.export));
        }
    }

    /**
     *
     * Exports the chart data to CSV
     * @param {string} exportName
     * @param {Event} event
     * @returns {void}
     */
    #exportChartData(exportName, event) {
        event.preventDefault();

        const chartData = this.#getChartData();

        // Create the CSV from chart data
        const csv = chartData.datasets.map((dataset) => {
            return dataset.data.map((data, index) => {
                return `"${chartData.labels[index]} ${dataset.label}","${data}"`;
            }).join('\n');

        }).join('\n');

        const csvData = new Blob([csv], {type: 'text/csv'});
        const downloadUrl = window.URL.createObjectURL(csvData);
        const downloadLink = document.createElement('a');
        downloadLink.setAttribute('href', downloadUrl);
        downloadLink.setAttribute('download', `${exportName}.csv`);
        downloadLink.click();
    }

    /**
     * @returns {{datasets: string[], labels: string[]}}
     */
    #getChartData() {
        return {
            labels: JSON.parse(this.getAttribute('labels')),
            datasets: JSON.parse(this.getAttribute('datasets')),
        };
    }
}

customElements.define('line-chart', LineChart);
